import { BusinessCategory } from 'models/entities/business-category';

class Condition {

  constructor() {
  }

  filter(all: BusinessCategory[]): BusinessCategory[] {
    return all.filter(it => this.check(it));
  }

  private check(category: BusinessCategory): boolean {
    return true;
  }

}

export { Condition };