import React, { useState, useEffect } from 'react';

import { BusinessCategory } from 'models/entities/business-category';
import { Model } from './index.model';

import { useAuth } from 'views/services/auth';
import { useModal, ModalResult } from 'views/services/modal';
import { InterfaceLoading } from 'views/components/interface-loading';
import { NoInterfaceData } from 'views/components/no-interface-data';
import { Header } from 'views/components/header';
import { Root, Frame, List } from './index.styled';

const BusinessCategories = () => {

  const auth = useAuth();
  const modal = useModal();
  const [model, setModel] = useState(new Model());

  function edit(category?: BusinessCategory) {
    const target = category ?? model.build();
    const tell = apply;
    modal.open('edit-business-category', { target, strict: true, tell });
  }

  function apply(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof BusinessCategory)) return;
    const category = result.target as BusinessCategory;
    switch (result.state) {
      case 'created': setModel(model.add(category)); break;
      case 'updated': setModel(model.replace(category)); break;
      case 'deleted': setModel(model.remove(category)); break;
    }
  }

  function watchAuth() {
    if (!auth.ok) return;
    model.read().then(model => setModel(model));
  }

  function watchCollection() {
    if (!model.collection) return;
    if (!model.collection.nextToken) return;
    model.readAllCollection().then(it => setModel(it));
  }

  useEffect(watchAuth, [auth.ok]);
  useEffect(watchCollection, [model.collection]);

  return (
    <Root>
      <Header mode={auth.user.admin ? 'admin' : ''} onCreate={() => edit()} />
      {!model.collection && <InterfaceLoading />}
      {model.list && (
        <Frame>
          {!model.list.length ? <NoInterfaceData>No Business Category</NoInterfaceData> : (
            <List>
              <table>
                <thead>
                <tr>
                  <th>Name</th>
                </tr>
                </thead>
                <tbody>
                {model.list.map((it, i) => (
                  <tr key={i} onClick={() => edit(it)}>
                    <td>{it.name}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </List>
          )}
        </Frame>
      )}
    </Root>
  );

};

export default BusinessCategories;